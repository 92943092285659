.fsp-content{
	width: 650px;
	height: 340px;
}

.fsp-wrapper{
	background: rgba(255, 255, 255, 0.8)!important;
}

.fancybox-slide > *{
	padding: 0px !important;
	overflow: visible !important;
	width: 100% !important;
	background: rgba(0,0,0,0.0) !important;
}

.container_popup{
	margin: 0 auto;
	max-width: 1180px;
	position: relative;
}

.container_popup_leistungen{
	margin: 0 auto;
	max-width: 960px;
	position: relative;
}

.shadow{
	-webkit-box-shadow: 0px 0px 60px -5px rgba(98,102,105,0.8);
	-moz-box-shadow: 0px 0px 60px -5px rgba(98,102,105,0.8);
	box-shadow: 0px 0px 60px -5px rgba(98,102,105,0.8);
}

.fancybox-bg{
	background-color: #5f788d !important;
}

.fancybox-is-open .fancybox-bg{
	opacity: .9 !important;
}

.cpp{
	border-radius: 15px;
	overflow: hidden;
}

.container_popup h3{
	text-transform: none;
	line-height: 1.6rem;
	font-size: 1.2rem;
}
