.ie .logo{
  height: 80px;
}

.ie .pulse{
  -webkit-animation-name: none !important;
  animation-name: none !important;
}

.ie .animated{
  -webkit-animation-duration: unset !important;
  animation-duration: unset !important;
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}

.ie h1, .ie h2, .ie h3, .ie h4, .ie h5, .ie h6{
  letter-spacing: -0.05rem !important;
}

/* IE object-fit:cover */

.iec{
	font-family: 'object-fit: cover; object-position: bottom;' !important;
	background-position-y: center !important;
}


.ie9 .nav li{
  padding: 40px 15px;
  display: inline-block;
}

.ie9 .nav li a{
  padding: 0px;
}

.ie9 .navfix li{
  padding: 30px 15px;
}

.ie9 .navfix li a{
  padding:0px;
}

.ie9 .sub-menu li{
  padding: 0px;
}

.ie9 .sub-menu li:first-child{
  padding: 0px;
}

.ie9 .sub-menu li:last-child{
  padding: 0px;
}

.ie9 .sub-menu li a{
  position: relative;
  width: 100%;
  padding-left: 0px;
  margin: 0px;
  padding: 20px 25px;}
