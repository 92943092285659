.txtul ul{
  margin: 0px;
  padding: 10px 0px 10px 30px;
}

.txtul ul li{
  color: $black;
  font-family: $baseFont;
  font-size: 14px;
  font-weight: 300;
}

.txtul ol{
  margin: 0px;
  padding: 10px 0px 10px 40px;
  counter-reset: item;
}

.txtul ol li{
  color: $black;
  font-family: $baseFont;
  font-size: 14px;
  font-weight: 300;
  padding: 12px 0;
  counter-increment: item;
  list-style: none !important;
}

.txtul ol li:first-child{
  padding: 0 0 12px 0;
}

.txtul ol li:last-child{
  padding: 12px 0 0 0;
}


.txtul ol li::before{
  content: counter(item) ". ";
  font-family: $baseFont;
  font-size: 14px;
  font-weight: 400;
  color: $green;
  position: absolute;
  left: 0px;
  border: 1px solid $green;
  border-radius: 20px;
  padding: 8px;
  line-height: 12px;
  width: 30px;
  height: 30px;
}
