/*
Theme Name: Silvamed
Theme URI: https://www.silvamed.de
Author: Sadik Aslan / Silvatel GmbH
Author URI: http://www.silvatel.de
Description: Silvamed Medizinisches Versorgungszentrum
Version: 1.0
Tags: SVM
*/

/* Wordpress CSS */

// Normalize
@import "base/normalize";
@import "base/webfonts";

// Mixins
@import "base/mixins";

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/headings";
@import "base/grid";
@import "base/spacings";
@import "base/buttons";

// Global CSS
@import "base/global";

// Wordpress Core style
@import "wp/core";

// External Libs
@import "lib/animate/animate";
@import "lib/royal_slider/royalslider";
@import "lib/mmenu/mmenu";
@import "lib/owlcarousel/owlcarousel";
@import "lib/fancybox/fancybox";

// Basic Layout
@import "header";
@import "main";
@import "footer";


// Modules
@import "modules/01-imageslider";
@import "modules/02-textfield";
@import "modules/03-carousel";
@import "modules/04-accordion";
@import "modules/05-tabs";
@import "modules/06-news";
@import "modules/07-treatment";
@import "modules/08-parallax";
@import "modules/09-philosophie";
@import "modules/10-aerzte";
@import "modules/11-fb";
@import "modules/12-fancy";
@import "modules/13-online-termin";
@import "modules/14-kontakt";

// Responsive
@import "base/responsive";
@import "responsive";
@import "ie";
