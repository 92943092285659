.sidebar{
  padding: 0px;
  margin: 0px;
}

.sidebar li{
  width: 100%;
  position: relative;
  float: left;
  list-style: none;
  margin: 0 0 10px;
  border-radius: 35px;
  overflow: hidden;
}

.sidebar li a{
  margin: 0px;
  width: 100%;
  cursor: pointer;
  padding: 20px 0 20px 40px;
  background-color: $grey;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}


.sidebar li a::before{
  background-color: $blue;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  z-index: -1;
}

.sidebar li a:hover::before{
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.sidebar li:nth-child(2n) a{
  background-color: #fbfbfb;
}

.sidebar li a{
  color: $black;
}

.sidebar li a:hover{
  color: $white;
}

.sidebar .current_page_item a{
  background-color: $green !important;
  color: $white !important;
}
