//
// Buttons
//

.button{
  border: solid 1px;
  padding: 15px 40px;
  position: relative;
  float: left;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: $baseFont;
  cursor: pointer !important;
  margin-top: 15px;
  color: $green;
  border-color: $green;
  margin-top: 40px;
  display: inline-block;
}

a.button:hover{
  background-color: $green;
  color: $white !important;
}

.button-white-s{
  padding: 15px 40px;
  position: relative;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: $baseFont;
  cursor: pointer !important;
  margin-top: 15px;
  color: $white;
  background-color: $green;
  display: inline-block;
}

a.button-white-s:hover{
  background-color: $green;
  color: $white !important;
}

#topslider .button{
  border: solid 1px;
  padding: 15px 30px;
  position: relative;
  float: left;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: $baseFont;
  cursor: pointer !important;
  margin-top: 15px;
}

#topslider a.button:hover{
  background-color: $white;
  color: $green !important;
}

.txtcenter .button{
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.white .button{
  border-color: $white;
  cursor: pointer;
}

.white .button:hover{
  border-color: $white;
  background: $white;
  color: $black !important;
}

@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
