.news-img a::before{
  content: "+";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $green;
  z-index: 222;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  line-height: 2.4rem;
  font-size: 20px;
  animation: none;
}

.news-img:hover a::before{
  transition: all 600ms ease 0s;
  animation: pulse2 1s infinite;
}

.news-img a{
  margin: 0px;
}

.news-img{
  object-fit: cover;
  overflow: hidden;
}

.news-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.0);
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}

.news-img:hover img{
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.05) !important;
  -webkit-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}

.news-content{
  border-radius: 15px;
  overflow: hidden;
}

.news-content h3:hover{
  color: $green;
  text-decoration: none !important;
}

.news-content .button{
  margin-top: 20px;
}

.wp-pagenavi{
  text-align: center;
  padding-top: 60px;
}

.wp-pagenavi a, .wp-pagenavi span{
  border: none !important;
}

.wp-pagenavi .pages{
  font-size: 14px;
  color: $black;
  font-family: $baseFont;
  font-weight: 300;

}

.wp-pagenavi a, .wp-pagenavi span{
  margin: 0 8px !important;
}

.wp-pagenavi .current{
  font-size: 14px;
  color: $white;
  font-family: $baseFont;

  background-color: #bfd178;
  border-radius: 25px;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 15px;
  background-color: $green;
}

.wp-pagenavi a{
  color: $black;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 4px 5px !important;
  -webkit-transition: none;
  transition: none;
}

.wp-pagenavi a:hover{
  background-color: $green;
  border-radius: 25px;
  color: $white;
}

.news-parent .news-content{
  margin-top: 40px;
}

.sidebar-news img{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  opacity: 0.8;
  @include simple-animation;
}

.sb-news-content{
  padding-bottom: 10px;
}

.sb-news-content h3{
  padding-top: 15px;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.sb-news-content:hover h3{
  color: $green;
  text-decoration: none !important;
  @include simple-animation;
}

.sb-news-content a:hover{
  text-decoration: none !important;
}

.sb-news-content:hover img{
  opacity: 1;
}


.sb-news-content span{
  padding-left: 5px;
}

.sb-news-content:last-child{
  padding-bottom: 0px;
}

.sb-news-content:last-child hr{
  display: none;
}

.news-content h3{
  line-height: 1.8rem;
}

.mh-news{
  min-height: 240px;
}

@media all and (max-width:1400px){
  .mh-news{
    min-height: 300px;
  }
}
