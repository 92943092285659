.st-tabs ul{
  padding: 0px;
  text-align: center;
  margin: 0 auto;
  display: table;
}

.st-tabs ul li{
  float: left;
  text-decoration: none;
  position: relative;
  list-style: none;
  min-width: 200px;
}

.st-tabs ul li:first-child{
  padding-left: 0px;
}

.st-tabs ul li a{
  color: #c1c1c1;
  font-size: 21px;
  word-spacing: 50px;
  font-weight: 400;
  line-height: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  font-family: $baseFont;
  letter-spacing: 0.02rem;
  -webkit-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -ms-transition: all 0.4s ease; transition: all 0.4s ease;
}

.st-tabs li.ui-tabs-active a{
  color: $white;
  font-weight: 600;
  letter-spacing: 0rem;
  -webkit-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -ms-transition: all 0.4s ease; transition: all 0.4s ease;
  background: $green;
  border-radius: 30px;
  padding: 10px 30px;
}

.st-content{
  overflow:hidden;
  border-radius: 15px;
}

.st-content-z{
  z-index: 99;
}

.st-content-bg{
  position: absolute;
  bottom: 0px;
  right: -150px;
  width: 520px;
  opacity: 0.3;
}

.st-content-bg-vib{
  position: absolute;
  bottom: 10px;
  right: -100px;
  width: 550px;
  opacity: 0.3;
}

.st-content-bg-ed{
  position: absolute;
  bottom: 10px;
  right: -100px;
  width: 650px;
  opacity: 0.3;
}

.st-img{
  overflow: hidden;
  min-height: 640px;
  max-height: 680px;
  z-index: 99;
}

.st-img img{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}


.ind-229 .st-content-bg{
  width: 660px;
}
