#topslider{
  min-height: 280px;
  max-height: 800px;
  overflow: hidden;
}

#topslider-small{
  min-height: 280px;
  max-height: 650px;
  overflow: hidden;
}

#imageslider, #wpgmza_map{
  margin-top: 140px;
}


.h-content{
  position: absolute;
  z-index: 99;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10%;
  min-width: 500px;
  min-height: 500px;
  max-width: 500px;
  max-height: 500px;
  border-radius: 100%;
  border: solid 0px;
  padding: 120px 70px 80px 90px;
  background-color: rgba(191, 209, 120, 0.9);
}

.wrapper-slider{
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
}

.h-content p:first-child{
  @include font-size($fontsize-XL);
  color: $white;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}

.page-id-18 .rsImg{
margin-top: -50px !important;
}


/* Height
   ========================================================================== */



@media all and (max-height:980px){

  .page-id-18 .rsImg{
  margin-top: 0px !important;
  }



  #topslider{
      max-height: 540px;
  }

  #topslider-small, #wpgmza_map{
      max-height: 480px;
  }

  #topslider img{
      margin-top: -60px;
      @include simple-animation;
  }

  .h-content{
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    right: 9%;
    top: 3%;
    @include simple-animation;
  }

}


@media all and (max-height:850px){
  #topslider{
     max-height: 520px;
 }

  #topslider-small, #wpgmza_map{
      max-height: 460px;
  }

  #topslider img{
      margin-top: -60px;
      @include simple-animation;
  }

  .h-content{
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
    right: 9%;
    top: 0%;
    @include simple-animation;
  }

}
