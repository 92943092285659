//
// Defaul Project Settings
//

// base font
$baseFont: 'Poppins', sans-serif;

// font sizes
$fontsize-XXL: 60;
$fontsize-XL: 28;
$fontsize-L: 18;
$fontsize-M: 15;
$fontsize-S: 13;


//breakpoints
$size-XXL2: 1920px;
$size-XXL: 1680px;
$size-XL2: 1260px;
$size-XL: 1024px;
$size-L: 850px;
$size-M2: 768px;
$size-M: 736px;
$size-S2: 600px;
$size-S: 480px;
$size-XS2: 380px;
$size-XS: 320px;
