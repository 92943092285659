.carouselB .owl-item {
  padding: 40px 0;
  border-radius: 15px;
}

.carouselB .center {
  z-index: 99;
  -webkit-box-shadow: 0 0 50px -30px rgba(82,82,82,0);
  -moz-box-shadow: 0 0 50px -30px rgba(82,82,82,0);
  box-shadow: 0 0 50px -30px rgba(82,82,82,0);
  background: #f8f8f8;
}

.carouselB .owl-stage-outer{
  height: 580px;
  padding: 40px 0;
}

.owl-carousel-b .carousel-content {
  height: 520px;
  border-radius: 15px;
  overflow: hidden;
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin: 0px;
  background: rgba(0,0,0,0);
}

.owl-carousel-b h3{
  line-height: 1.9rem;
  margin-bottom: 15px;
}

.carousel-content img {
  width: 100%;
  position: relative;
  float: left;
}

.carouselB .center .carousel-content {
  height: 400px;
  opacity: 1;
  margin: 0;
}

.owl-carousel-b .carousel-content {
  height: 400px;
}

.carousel-content .button {
  display: none;
  margin-top: 10px;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.center .carousel-content .button {
  display: block;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
}



.owl-carousel-b .owl-stage-outer{
  height: 580px;
}

.carousel-fix-b {
  min-height: 580px;
  max-width: 1480px;
}
/* Feel free to change duration  */
.animated {
  -webkit-animation-duration: 1000 ms;
  animation-duration: 1000 ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* .owl-animated-out - only for current item */
/* This is very important class. Use z-index if you want move Out item above In item */
.owl-animated-out {
  z-index: 1;
}
/* .owl-animated-in - only for upcoming item
/* This is very important class. Use z-index if you want move In item above Out item */
.owl-animated-in {
  z-index: 0;
}
/* .fadeOut is style taken from Animation.css and this is how it looks in owl.carousel.css:  */
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/* owl Carousel NAV */

.carousel-fix-b .owl-dots{
  background: rgba(0,0,0,0);
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}


.carousel-fix-b .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.carousel-fix-b .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.carousel-fix-b .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.carousel-fix-b .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.carousel-fix-b .owl-dots .owl-dot.active span,
.carousel-fix-b .owl-dots .owl-dot:hover span {
  background: #4DC7A0;
}

.carousel-fix-b .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.carousel-fix-b .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.carousel-fix-b .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.carousel-fix-b .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.carousel-fix-b .owl-dots .owl-dot.active span,
.carousel-fix-b .owl-dots .owl-dot:hover span {
  background: $blue !important;
  cursor: pointer;
}

.carousel-fix-b .owl-dots.disabled,
.carousel-fix-b .owl-nav.disabled {
  display: none;
}

.carousel-fix-b .owl-dot,
.carousel-fix-b .owl-nav .owl-next,
.carousel-fix-b .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.carousel-fix-b .owl-nav button.owl-next,
.carousel-fix-b .owl-nav button.owl-prev,
.carousel-fix-b button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}



.carouselB .owl-nav{
  width: 100%;
  height: 100%;
  position: static;
  top: 0;
  left: 0;
  margin: 0px;
  z-index: 99;
}

.carouselB .owl-nav {
  display: block !important;
}

.carouselB .owl-carousel:hover .owl-nav{
  display: block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.carouselB .owl-prev{
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -20px;
  background-color: rgba(0,0,0,0) !important;
  width: auto;
  height: auto;
  border-radius: 30px !important;
  opacity: 0.4;
}

.carouselB .owl-prev span{
  font-size: 70px;
  font-weight: 300;
  font-family: $baseFont;
  color: $black;
}

.carouselB .owl-prev:hover{
  opacity: 0.8;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}


.carouselB .owl-next{
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -20px;
  background-color: rgba(0,0,0,0) !important;
  width: auto;
  height: auto;
  border-radius: 30px !important;
  opacity: 0.4;
}

.carouselB .owl-next span{
  font-size: 70px;
  font-weight: 300;
  font-family: $baseFont;
  color: $black;
}

.carouselB .owl-next:hover{
  opacity: 0.8;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
