/**
 * GRID System
 */
.col {
  display: block;
  position: relative;
  float: left;
  box-sizing: border-box;
}

.container .col:first-child {
  margin-left: 0;
}

.g-auto {
  width: auto;
}


.g-s-05 {
  width: 3.333%;
}

.g-1 {
  width: 8.333%;
}

.g-2 {
  width: 16.667%;
}

.g-20 {
  width: 20%;
}

.g-3 {
  width: 25.0%;
}

.g-4 {
  width: 33.333%;
}

.g-5 {
  width: 41.667%;
}

.g-6 {
  width: 50%;
}

.g-7 {
  width: 58.333%;
}

.g-8 {
  width: 66.667%;
}

.g-9 {
  width: 75.0%;
}

.g-10 {
  width: 83.333%;
}

.g-11 {
  width: 91.667%;
}

.g-115 {
  width: 95%;
}

.g-12 {
  width: 100%;
}

.g-20p {
  width: 20%;
}

.pl-1 {
  padding-left: 8.333%;
}

.ml-1 {
  margin-left: 8.333%;
}

.ml-h-1 {
  margin-left: 4.1665%;
}

.pl-h-1 {
  padding-left: 4.665%;
}

.pl-2 {
  padding-left: 16.667%;
}

.pl-3 {
  padding-left: 25.0%;
}

.pl-4 {
  padding-left: 33.333%;
}

.pl-5 {
  padding-left: 41.667%;
}

.pl-6 {
  padding-left: 50.0%;
}

.pl-7 {
  padding-left: 58.333%;
}

.pl-8 {
  padding-left: 66.667%;
}

.pl-9 {
  padding-left: 75.0%;
}

.pl-10 {
  padding-left: 83.333%;
}

.pl-11 {
  padding-left: 91.667%;
}

.pr-1 {
  padding-right: 8.333%;
}

.pr-h-1 {
  padding-right: 4.665%;
}

.pr-2 {
  padding-right: 16.667%;
}

.pr-3 {
  padding-right: 25.0%;
}

.pr-4 {
  padding-right: 33.333%;
}

.pr-5 {
  padding-right: 41.667%;
}

.pr-6 {
  padding-right: 50.0%;
}

.pr-7 {
  padding-right: 58.333%;
}

.pr-8 {
  padding-right: 66.667%;
}

.pr-9 {
  padding-right: 75.0%;
}

.pr-10 {
  padding-right: 83.333%;
}

.pr-11 {
  padding-right: 91.667%;
}
