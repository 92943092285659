.parallax-color{
  display: none;
}

.only-resp{
  display: none;
}

/* Wrapper Dynamisch
   ========================================================================== */

@media all and (max-width:1360px){}

@media all and (max-width:1300px){}

@media all and (max-width:1420px){}

@media all and (max-width:1360px){}

@media all and (max-width:1320px){}

@media all and (max-width:1280px){

  .wrapper{
    padding: 0 50px;
  }

  .logo {
    width: 150px;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .nav li a{
    padding: 37px 12px;
  }

  .navfix li a{
    padding: 27px 12px;
  }

  .ie9 .nav li{
    padding: 37px 12px;
  }

  .ie9 .nav li a{
    padding: 0px;
  }

  .ie9 .navfix li a{
    padding:0px;
  }



  .sub-menu li {
    width: 100%;
    position: relative;
    float: left;
    padding: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-transform: none;
    transform: none;
    box-shadow: none;
    -webkit-transition-property: none;
    transition-property: none;
    -webkit-transition-duration: none;
    transition-duration: none;
  }

  .st-img img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
  }

  .st-img {
    min-height: 630px;
  }

  .st-content-bg{
    width: 450px;
  }

  .ind-229 .st-content-bg{
    width: 620px;
  }


  .navfix .logo{
    padding-top: 10px;
    width: 140px;
    padding-bottom: 12px;
  }

  .sub-menu li a{
    padding: 20px 25px;
    }

    .navfix .sub-menu li a{
    padding: 20px 25px;
    }

    .sub-menu .menu-item-has-children::after{
      right: 15px;
      top: 37%;
      content: "▸";
    }

    .navfix .sub-menu .menu-item-has-children::after{
      right: 15px;
      top: 37%;
      content: "▸";
    }


}

@media all and (max-width:1120px){
  .nav li a{
    padding: 37px 10px;
  }

  .navfix li a{
    padding: 27px 10px;
  }

  .ie9 .nav li{
    padding: 37px 10px;
  }

  .ie9 .nav li a{
    padding: 0px;
  }

  .ie9 .navfix li a{
    padding:0px;
  }

  .menu-item-has-children::after {
    right: 0px;
  }

  .navfix .menu-item-has-children::after {
    right: 0px;
  }

  .sub-menu li a{
  padding: 20px 25px;
  }

  .navfix .sub-menu li a{
  padding: 20px 25px;
  }

  .sub-menu .menu-item-has-children::after{
    right: 15px;
    top: 37%;
    content: "▸";
  }

  .navfix .sub-menu .menu-item-has-children::after{
    right: 15px;
    top: 37%;
    content: "▸";
  }




}



/* Media Queries - Breakpoints - Responsive
   ========================================================================== */


/* iPad Pro Portrait & iPad Landscape 1024px -> 1080 */
@media all and (max-width:1080px){

  .zertifikate .g-4{
    width: 50%;
    padding-top: 20px;
  }

  .sub-menu .menu-item-has-children::after{
    content: "" !important;
  }

  .m-txt .g-4, .m-txt .g-8, .m-txt .g-115{
    width: 100%;
    float: left;
  }

  .pt-240 {
      padding-top: 180px;
  }

  .h-content{
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    right: 5%;
    top: 3%;
    @include simple-animation;
  }

  .m-none{
    display: none;
  }

  .res-nav{
    display: block;
    padding-top: 26px;
    padding-right: 0px;
  }

	.burgermenu{
		display: block;
		float: left;
	}

  h1, h2{
    font-size: 1.50rem;
    margin: 0 0 5px 0;
    line-height: 2rem;
  }

  .ast-headline h2{
    font-size: 1.35rem
  }

  .doc-text h2{
    font-size: 1.5rem;
  }

  h3{
    font-size: 1.2rem;
  }

  .button{
    margin-top: 20px;
  }

  .button-white-s{
    margin: 0px;
  }

  .social-footer span{
    font-size: 23px;
  }

  .nav{
    display: none;
  }

  .social-header{
    display: none;
  }

  .ptb-80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pb-80 {
      padding-bottom: 40px;
  }

  .pt-40 {
      padding-top: 20px;
  }

  .pt-80 {
    padding-top: 40px;
  }

  .g-20 {
    width: 25%;
  }

  .logo{
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ptb-100{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .dr-parallax{
    min-height: 300px;
  }

  .st-content-bg{
    width: 410px;
  }

  .ind-229 .st-content-bg{
    width: 620px;
  }

  .st-img img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .carouselB .owl-stage-outer{
    padding: 10px 0;
  }

  .carouselB .owl-item{
    padding: 20px 0;
  }

  .owl-carousel-b .owl-stage-outer {
      height: 500px;
  }

  .carousel-fix-b{
    min-height: 500px;
  }

  .parallax-color{
    display: block;
    background-color: rgba(87, 88, 96, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  .m-pl{
    z-index: 10;
    position: relative;
  }

  .pb-40 {
    padding-bottom: 20px;
  }

  .doc-parent{
    width: 50%;
  }

  .doc-parent:nth-child(3n+2) .doc-content{
    float: left;
  }

  .doc-parent:nth-child(3n+3) .doc-content{
    margin: 0;
    float: left;
  }

  .container_popup{
    max-width: 960px;
  }

  #wpgmza_map{
    height: 420px !important;
    max-height: 420px !important;
  }

}


@media all and (max-width:980px){
  #wpgmza_map{
    height: 400px !important;
    max-height: 400px !important;
  }


  .kontaktformular select, .kontaktformular input{
  	width: 100% !important;
  }



  .pt-240 {
      padding-top: 120px;
  }

  .res-nav{
    padding-top: 30px;
  }



  #imageslider, #wpgmza_map{
    margin-top: 80px;
  }

  .nf-none{
    display: none;
  }

  .h-content{
    display: none;
  }

  .only-resp{
    display: block;
    top: -5px;
    padding-top: 60px;
    padding-bottom: 60px;
  }


  .only-resp .button{
    margin-top: 10px;
  }

  .m-txt .g-6{
    width: 100%;
  }

  .m-txt .g-6 .pr-fr{
    float: left;
  }

  h1, h2 {
      font-size: 1.3rem;
      margin: 0 0 5px 0;
      line-height: 1.7rem;
  }

  .doc-text h2{
    font-size: 1.1rem;
  }

  .ast-headline h2 {
      font-size: 1.1rem;
  }

  h3 {
      font-size: 1.1rem;
      line-height: 1.5rem;
  }

  p, a, .button, .button-white-s {
    font-size: 12px;
    line-height: 1.3rem;
  }

  .zae-single h3 {
      font-size: 12px;
  }

  .accordion-1 h3{
    font-size: 13px;
    padding-left: 50px;
  }

  .accordion-1 h3::before{
    left: 0px;
  }

  .center .carousel-content{
    height: 550px;
  }

  .carousel-content{
    height: 480px;
  }

  .carousel-fix{
    min-height: 620px;
  }

  .owl-stage-outer {
      height: 620px;
      padding: 10px 0;
  }

  .g-8 {
      width: 90%;
  }

  .g-20 {
      width: 33.333%;
  }

  .dr-parallax {
      min-height: 270px;
  }

  .st-tabs ul li a{
    font-size: 18px;
  }


  .st-img{
    display: none;
  }

  .st-content-bg{
    width: 520px;
  }

  .ind-229 .st-content-bg{
    width: 700px;
  }

  .wrapper {
      padding: 0 40px;
  }

  .ast-img{
    height: 320px;
  }

  .mh-300 {
      height: 320px;
  }

  .container_popup {
      max-width: 650px;
  }

  .container_popup .offset{
      display: none;
  }

  .container_popup .mh-460 .g-6{
    width: 100%;
    padding: 0 40px;
  }

  .container_popup .mh-460 {
    min-height: 300px;
  }

  .txtul ul{
    padding: 5px 0px 5px 20px;
  }

  .txtul ul li, .txtul ol li{
    font-size: 12px;
  }

  .m-block-sidebar{
    display: none;
  }

  .block{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .block-1{
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    width: 100%;
    padding-bottom: 20px;
  }

  .block-2{
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .pt-60 {
      padding-top: 10px;
  }

  .zae-single:first-child{
    padding-top: 0px;
  }

  .zae-single{
    padding-top: 30px;
  }

  .zae-single hr{
    width: 60%;
  }

  footer .pb-50 {
      padding-bottom: 20px;
  }

  footer .pt-60 {
    padding-top: 20px;
  }

  .only-resp{
    padding-top: 40px;
    padding-bottom: 40px;
  }



}


/* Standard Width*/
@media all and (max-width:960px){}

/* iPad Portrait */

@media all and (max-width:768px){}


/* iPhone 6s Plus Landscape */

@media all and (max-width:736px){



  .lp-dr-m .st-img{
    display: block;
    width: 100%;
    height: 300px;
    position: relative;
    float: left;
    min-height: 300px;
  }

  .lp-dr-m .st-content-z{
    padding: 20px 0;
  }

  .lp-dr-m .st-content-z p{
    width: 98%;
  }


  #wpgmza_map{
    height: 300px !important;
    max-height: 300px !important;
  }

  .pt-240 {
      padding-top: 100px;
  }

  .navfix .logo {
      padding-top: 7px;
  }

  .res-nav{
    padding-top: 19px;
  }


  #imageslider, #wpgmza_map{
      margin-top: 60px;
  }

  #topslider {
    min-height: 220px;
  }

  .wrapper {
      padding: 0 20px;
  }

  h1, h2 {
      font-size: 1rem;
      margin: 0 0 5px 0;
      line-height: 1.4rem;
  }

  .accordion-1 h3::before{
    height: 20px;
    width: 20px;
    font-size: 1.3rem;
    line-height: 1rem;
  }

  .accordion-1 h3 {
    font-size: 0.8rem;
  }

  .logo {
      padding-top: 7px;
      padding-bottom: 10px;
      width: 130px;
  }

  .ptb-80 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .m-txt .g-6 .g-10{
    width: 100%;
  }

  .g-8 {
      width: 100%;
  }

  .pt-60 {
      padding-top: 20px;
  }

  .tr-img{
    width: 120px;
    height: 120px;
  }

  .g-20 {
      width: 50%;
  }

  .tr-content h3 {
      font-size: 12px;
  }

  .pt-20 {
      padding-top: 10px;
  }

  .st-tabs ul li a {
      font-size: 1rem;
  }

  .st-tabs ul li{
    width: 100%;
    margin-bottom: 15px;
  }

  .mt-40 {
      margin-top: 10px;
  }

  .st-content .st-content-z{
    width: 100%;
  }

  .st-content-bg{
    opacity: 0.08;
    width: 430px;
  }

  .ind-229 .st-content-bg{
    width: 620px;
  }


  h3 {
    font-size: 1rem;
  }

  .owl-carousel-b h3 {
      line-height: 1.5rem;
      margin-bottom: 15px;
  }

  .m-s-news-txt .g-6{
    width: 100%;
    text-align: center;
  }

  .m-s-news .g-6{
    margin-bottom: 15px;
  }

  .m-s-news .g-6, .m-s-news .g-6 .g-115{
    width: 100%;
  }

  .m-s-news .g-6, .m-s-news .g-6 .g-115 .g-11 .g-11{
    width: 100%;
  }

  .news-img a::before, .ast-content a::before, .owl-carousel .center .cfx a::before, .doc-content a::before{
    width: 30px;
    height: 30px;
    line-height: 1.9rem;
    font-size: 14px;
  }

  .date {
      padding: 0px 0;
  }

  .ptb-30 {
      padding-top: 10px;
      padding-bottom: 10px;
  }

  .news-content .button {
      margin-top: 5px;
  }

  .social-footer span {
      font-size: 18px;
  }

  footer{
    background: rgba(0,0,0,0);
    background-image: none !important;
  }

  .pb-60 {
      padding-bottom: 20px;
  }

  .f-content .g-6{
    width: 100%;
    text-align: left;
    float: left;
  }

  .f-content .g-6 .pr-fr{
    text-align: left;
    float: left;
  }

  .st-content-z .button{
    background-color: $white;
  }

  #topslider-small {
    min-height: 180px;
  }

  .pt-80 {
      padding-top: 20px;
  }

  .pb-50 {
      padding-bottom: 20px;
  }

  .doc-parent{
    width: 100%;
  }

  .doc-text {
      min-height: 0;
  }

  .doc-img{
    height: 210px;
  }

  .doc-content{
    width: 100%;
  }

  .doc-text h3 {
    font-size: 0.9rem;
    padding-top: 10px;
  }

  .ptb-40 {
      padding-top: 20px;
      padding-bottom: 20px;
  }

  .news-parent .news-content {
        margin-top: 0px;
    }

  .news-parent .g-6{
    width: 100%;
    margin-bottom: 15px;
  }

  .g-115 {
      width: 100%;
  }

  .wp-pagenavi{
    padding-top: 10px;
  }

  .wp-pagenavi .pages{
    font-size: 12px;
  }

  .wp-pagenavi .current {
    font-size: 10px;
    width: 40px;
    height: 40px;
    padding: 13px;
  }

  .wp-pagenavi a, .wp-pagenavi span {
    margin: 0 3px !important;
  }

  .m-single-sidebar{
    display: none;
  }

  .g-9{
    width: 100%;
  }

  .mh-300 {
    height: 280px;
  }

  .ast-img {
    height: 280px;
  }

  .ot-tabs .st-tabs ul{
    padding-bottom: 10px;
  }

  .carouselB .tr-img {
    width: 200px;
    height: 200px;
  }

  .center .carousel-content {
        height: 500px;
    }

  .carouselA .owl-nav{
    display: none !important;
  }

  .owl-stage-outer {
    height: 510px;
  }

  .carousel-fix {
      min-height: 560px;
  }

  .carousel-fix .owl-dots .owl-dot span, .owl-carousel-b .owl-dots .owl-dot span{
      margin: 5px 3px;
  }

  footer .pb-50 {
      padding-bottom: 20px;
  }

  footer .pt-60 {
    padding-top: 20px;
  }

  .only-resp{
    padding-top: 20px;
    padding-bottom: 20px;
  }

}


/* Nexus 6P Landscape */

@media all and (max-width:732px){}



/* iPhone 6s Landscape */

@media all and (max-width:667px){

  .zertifikate .g-4{
    width: 100%;
    padding-top: 20px;
    float: left;
    position: relative;
  }

  .zertifikate .g-4 img{
    width: 100%;
  }

  #wpgmza_map{
    height: 240px !important;
    max-height: 240px !important;
  }

  .ind-229 .st-content-bg{
    width: 480px;
  }

}


/* Samsung Galaxy S5 Landscape */

@media all and (max-width:640px){}


/* iPhone 5 Landscape */

@media all and (max-width:568px){}


/* iPhone 6s Plus Portrait */

@media all and (max-width:414px){

  .doc-img {
      height: 240px;
  }

  .mh-300 {
    height: 240px;
}

}


/* Nexus 6P Portrait */

@media all and (max-width:412px){}


/* iPhone 6s Portrait */

@media all and (max-width:375px){}


/* Samsung Galaxy S5 Portrait */

@media all and (max-width:375px){}


/* iPhone 5 Portrait */

@media all and (max-width:320px){

  .owl-stage-outer {
      height: 470px;
  }

  .center .carousel-content {
      height: 460px;
  }

  .carousel-fix {
      min-height: 510px;
  }

  .st-content-bg {
    opacity: 0.05;
    }

  .ptb-60 {
      padding-top: 30px;
      padding-bottom: 30px;
  }

  #topslider-small {
      min-height: 160px;
  }

}


/* Responsive Height
   ========================================================================== */

@media all and (max-height:414px){
  .logo {
      padding-top: 7px;
      padding-bottom: 7px;
      width: 120px;
  }

  #imageslider, #wpgmza_map {
      margin-top: 50px;
  }

  #topslider{
    min-height: 250px;
    max-height: 250px;
  }

  #topslider-small{
    min-height: 250px;
    max-height: 250px;
  }

  .navfix .logo {
    padding-top: 7px;
  }

  .m-single-sidebar{
    display: none;
  }


    .wp-pagenavi{
      padding-top: 10px;
    }

    .wp-pagenavi .pages{
      font-size: 12px;
    }

    .wp-pagenavi .current {
      font-size: 10px;
      width: 40px;
      height: 40px;
      padding: 13px;
    }

    .wp-pagenavi a, .wp-pagenavi span {
      margin: 0 3px !important;
    }

    .single .g-9{
      width: 100%;
    }

    .only-resp{
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .doc-parent {
      width: 50%;
      min-height: 370px;
      padding: 6px;
      }

      .doc-text {
        min-height: 140px;
    }

}
