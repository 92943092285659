.kontaktformular{
	max-width: 860px;
	padding-top: 60px;
}


/* Kontaktformular */

.kontaktformular input{
	background: rgba(0,0,0,0);
	border: solid 1px #ababab;
	padding: 18px 0 18px 20px;
	margin: 15px 0;
	font-size: 14px;
	width: 98%;
	border-radius: 10px;
}

.kontaktformular select{
	background: rgba(0,0,0,0);
	border: solid 1px #ababab;
	font-family: $baseFont !important;
	color: $black !important;
	font-weight: 300 !important;
	padding: 10px 0 10px 20px;
	margin: 5px 0 15px 0;
	font-size: 14px;
	width: 98%;
	border-radius: 10px;
	max-width: 200px;
	opacity: 1 !important;
}


.kf-block-2 div:last-child input, .kf-block-3 div:last-child input{
	width: 100%;
}

.kf-block-4, .kf-block-5{
	margin-top: 20px;
}

.kf-block-4 input{
	width: 100%;
}

.kontaktformular p{
	color: $black;
}

.kontaktformular textarea{
    width: 100%;
    background: rgba(0,0,0,0);
    border: solid 1px #ababab;
		padding: 18px 0 18px 20px;
		margin: 15px 0;
		font-size: 14px;
		width: 100%;
		border-radius: 10px;
}

.kontaktformular .message textarea[placeholder]{
	padding-top: 20px;
	padding-left: 20px;
	font-family: $baseFont;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.2rem;
	margin: 0.6rem 0;
	color: #ababab;
}

textarea:focus{
	color: #000 !important;
}

.wpcf7-submit{
	border: solid 1px !important;
  padding: 15px 40px !important;
  position: relative !important;
  float: left !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: $baseFont !important;
  cursor: pointer !important;
  margin-top: 15px !important;
  color: $green !important;
  border-color: $green !important;
  margin-top: 40px !important;
  display: inline-block !important;
	-webkit-transition: all 0.3s ease !important;
	-moz-transition: all 0.3s ease !important;
	-ms-transition: all 0.3s ease !important;
	transition: all 0.3s ease !important;
	width: auto !important;

}

.wpcf7-submit:hover{
	background-color: $green !important;
  color: $white !important;
}



/* Checkboxes */

		input[type="checkbox"]{
		  border: 0 !important;
		  clip: rect(0 0 0 0) !important;
		  height: 1px !important;
		  margin: -1px !important;
		  overflow: hidden !important;
		  padding: 0 !important;
		  position: absolute !important;
		  width: 1px !important;
			cursor: pointer !important;
		}

		input[type="checkbox"]:focus + .wpcf7-list-item-label:before, input[type="checkbox"]:hover + .wpcf7-list-item-label:before{
			border-color: $green !important;
			cursor: pointer !important;
		}

		input[type="checkbox"]:active + .wpcf7-list-item-label:before{
		  -webkit-transition-duration: 0 !important;
				  transition-duration: 0 !important;
		  -webkit-filter: brightness(0.2) !important;
				  filter: brightness(0.2) !important;
		}
		input[type="checkbox"] + .wpcf7-list-item-label{
		  position: relative !important;
		 display: block !important;
		  padding-bottom: 1.5em;
		  padding-left: 2em !important;
		  vertical-align: middle !important;
		  -webkit-user-select: none !important;
			 -moz-user-select: none !important;
			  -ms-user-select: none !important;
				  user-select: none !important;
		}

		input[type="checkbox"] + .wpcf7-list-item-label:before{
		  box-sizing: content-box !important;
		  content: '' !important;
		  color: $green !important;
		  position: absolute !important;
		  top: 0px !important;
		  left: 0 !important;
		  width: 18px !important;
		  height: 18px !important;
		  border: 1px solid $green !important;
		  text-align: center !important;
			cursor: pointer !important;
		}

		input[type="checkbox"] + .wpcf7-list-item-label:after{
		  box-sizing: content-box !important;
		  content: '' !important;
		  background-color: $green !important;
		  position: absolute !important;
		  top: 10px !important;
		  left: 5px !important;
		  width: 10px !important;
		  height: 10px !important;
		  margin-top: -5px !important;
		  -webkit-transform: scale(0) !important;
				  transform: scale(0) !important;
		  -webkit-transform-origin: 50% !important;
				  transform-origin: 50% !important;
		  -webkit-transition: -webkit-transform 200ms ease-out !important;
		  transition: -webkit-transform 200ms ease-out !important;
		  transition: transform 200ms ease-out !important;
		}

		input[type="checkbox"] + .wpcf7-list-item-label:after {
		  background-color: transparent !important;
		  top: 10px !important;
		  left: 5px !important;
		  width: 8px !important;
		  height: 3px !important;
		  margin-top: -4px !important;
		  border-style: solid !important;
		  border-color: $green !important;
		  border-width: 0 0 3px 3px !important;
		  -o-border-image: none !important;
			 border-image: none !important;
		  -webkit-transform: rotate(-45deg) scale(0) !important;
				  transform: rotate(-45deg) scale(0) !important;
		  -webkit-transition: none !important;
		  transition: none !important;
		}
		input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
		  content: '' !important;
		  -webkit-transform: rotate(-45deg) scale(1) !important;
				  transform: rotate(-45deg) scale(1) !important;
		  -webkit-transition: -webkit-transform 200ms ease-out !important;
		  transition: -webkit-transform 200ms ease-out !important;
		  transition: transform 200ms ease-out !important;
		}

		input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
		  -webkit-animation: borderscale 200ms ease-in !important;
				  animation: borderscale 200ms ease-in !important;
		}
		input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
		  -webkit-transform: rotate(-45deg) scale(1) !important;
				  transform: rotate(-45deg) scale(1) !important;
		}

		@-webkit-keyframes borderscale {
		  50% {
			box-shadow: 0 0 0 2px $green !important;
		  }
		}

		@keyframes borderscale {
		  50% {
			box-shadow: 0 0 0 2px $green !important;
		  }
		}

.form-field .wpcf7-list-item-label{
	color: #000 !important;
	font-family: 'Nunito', sans-serif !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 1.2rem !important;
	padding-bottom: 0px !important;
}

.form-field{
	padding-bottom: 1.3em;
}

.form-field p{
	padding-left: 28px;
	padding-top: 3px;
}


.wpcf7-select{
	width: 100%;

}

.wpcf7-validation-errors, .wpcf7-mail-sent-ng, .wpcf7-spam-blocked{
	position: relative !important;
	float: left !important;
	background-color: #f07878 !important;
	border: 0px !important;
	padding: 30px !important;
	width: 100% !important;
	text-align: center !important;
	font-family: $baseFont !important;
	font-size: 14px !important;
	color: $white !important;
}

 .wpcf7-not-valid-tip{
	 font-family: $baseFont !important;
 	 font-size: 14px !important;
	 color: #f07878 !important;
	 margin-top: -10px !important;
 }

.wpcf7-acceptance{
	float: left;
}

span.wpcf7-list-item{
	margin: 0px !important;
}

.zustimmung  .wpcf7-not-valid-tip{
	position: relative;
	float: left;
	margin: 0px !important;
	padding-left: 20px;
}

.wpcf7-mail-sent-ok{
	position: relative !important;
	float: left !important;
	background-color: $green !important;
	border: 0px !important;
	padding: 30px !important;
	width: 100% !important;
	text-align: center !important;
	font-family: $baseFont !important;
	font-size: 14px !important;
	color: $white !important;
}


.lp-kontakt .kontaktformular{
	padding-top: 20px;
}
