/* Ärzte */
.doc-content{
  border-radius: 15px;
  overflow: hidden;
  width: 96%;
}

.doc-parent:nth-child(3n+2) .doc-content{
  float: right;
}

.doc-parent:nth-child(3n+3) .doc-content{

  margin: 0 auto;
  float: none;
}

.doc-text{
  min-height: 215px;
}

.doc-img{
  overflow: hidden;
  height: 280px;
  object-fit: cover;
}

.doc-img img{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.0);
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}

.doc-content a:hover img{
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.05) !important;
  -webkit-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}


.doc-text h3{
  font-size: 1.10rem;
  color: $black;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 1.3rem;
  text-transform: none;
  margin: 0px;
}

.doc-text h2{
  font-size: 1.55rem;
  margin: 0 0 8px 0;
}

.doc-content a{
  margin: 0px;
}

.doc-content a::before{
  content: "+";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $green;
  z-index: 222;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  line-height: 2.4rem;
  font-size: 20px;
  animation: none;
}

.doc-content:hover a::before{
  transition: all 600ms ease 0s;
  animation: pulse2 1s infinite;
}

@-webkit-keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(191, 209, 120, 0.6);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(191, 209, 120, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(191, 209, 120, 0);
  }
}
@keyframes pulse2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(191, 209, 120, 0.6);
    box-shadow: 0 0 0 0 rgba(191, 209, 120, 0.6);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(191, 209, 120, 0);
      box-shadow: 0 0 0 10px rgba(191, 209, 120, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(191, 209, 120, 0);
      box-shadow: 0 0 0 0 rgba(191, 209, 120, 0);
  }
}

.ofc-popup {
    overflow: hidden;
    object-fit: cover;
}

.ofc-popup img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mh-460{
  min-height: 460px;
}

.fancybox-close-small{
  display: none;
  top: 10px;
  right: 10px;
}

.appendclose{
  display: block;
}

.fancybox-close-small::after{
  font: 30px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: $black;
}

/* Parent Ärzte */

.mh-300{
  height: 400px;
}

.ast-img{
  overflow: hidden;
  height: 400px;
  object-fit: cover;
  cursor: pointer;
}

.ast-img img{
  opacity: 0.8;
}

.doc-parent:nth-child(3n+2) .st-content{
  margin: 0 auto;
  float: none;
}

.doc-parent:nth-child(3n+3) .st-content{
  float: right;
}

.ast-content a:hover img{
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.1) !important;
  -webkit-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
}

.ast-content{
  border-radius: 15px;
  overflow: hidden;
  width: 96%;
}

.ast-content a{
  margin: 0px;
}

.ast-content a::before{
  content: "+";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $green;
  z-index: 222;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  line-height: 2.4rem;
  font-size: 20px;
  animation: none;
}

.ast-content:hover a::before{
  transition: all 300ms ease 0s;
  animation: pulse2 1s infinite;
}

.ast-headline{
  position: absolute;
  z-index: 500;
  right: 0px;
  bottom: 0px;
  width: 100%;
}

.ast-img:hover h2{
  color: $green;
  text-decoration: none !important;
}

.ast-headline h2{
  color: $black;
  background-color: $white;
  padding: 20px 40px;
  margin: 0px;
  font-size: 1.55rem;
}

.zae-single h3{
  font-size: 14px;
  text-transform: none;
  font-weight: 300;
}

.zae-single .button-white-s{
  position: relative;
  float: left;
}

.zae-single hr{
  margin: 0.7em 0;
}
