//
// Footer
//

footer a{
  font-weight: 600;
}

.f-content{
  z-index: 100;
}

.social-footer span{
  font-size: 30px;
  padding: 0 15px;
  color: #797a84;
  cursor: pointer;
  -webkit-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -ms-transition: all 0.4s ease; transition: all 0.4s ease;

}

.social-footer span:hover{
  color: $green;
}

.siegel p{
  line-height: 1rem;
  margin: 0 0 15px 0;
}

footer .logo{
  padding-top: 0px;
}
