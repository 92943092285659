//
// Headings
//

p {
  font-size: 14px;
  color: $black;
  font-family: $baseFont;
  font-weight: 300;
  line-height: 1.5rem;
  margin: 8px 0;
  letter-spacing: 0;
}

a {
  font-size: 14px;
  color: $lightblue;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0.5rem 0;
  text-decoration: none;
  @include simple-animation;
}

a:hover {
  text-decoration: underline;
}

.nav a:hover, .button:hover, h1:hover, h2:hover, h3:hover, .button-white-s, .news-content a, .social-footer a, .social-header a, .carousel-content a, .sidebar li a{
  text-decoration: none !important;
}

td,
tr {
  color: $black;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0.5rem 0;
}

table {
  margin: 15px 0;
}

h1 {
  @include font-size($fontsize-XL);
  color: $black;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}

h2 {
  @include font-size($fontsize-XL);
  color: $black;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}

h3 {
  font-size: 1.40rem;
  color: $black;
  font-family: $baseFont;
  font-weight: 400;
  line-height: 2.3rem;
  text-transform: uppercase;
  margin: 0px;
}

h4 {
  font-size: 18px;
  font-family: $baseFont;
  font-weight: 600;
  line-height: 1.6rem;
  margin: 0px;
  text-transform: uppercase;
}

h5 {
}

h6 {
}

strong {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}
//
// Text align
//

.txtcenter {
  text-align: center;
}

.txtleft {
  text-align: left;
}

.txtright {
  text-align: right;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
