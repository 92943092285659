.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-70 {
	padding-top: 70px;
}

.pt-80 {
	padding-top: 80px;
}

.pt-90 {
	padding-top: 90px;
}

.pt-100 {
	padding-top: 100px;
}

.pt-240 {
	padding-top: 240px;
}

.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-100 {
	padding-bottom: 100px;
}

.ptb-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.ptb-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.ptb-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.ptb-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.ptb-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.ptb-40 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.ptb-50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.ptb-60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.ptb-70 {
	padding-top: 70px;
	padding-bottom: 70px;
}

.ptb-80 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.ptb-90 {
	padding-top: 90px;
	padding-bottom: 90px;
}

.ptb-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-70 {
	margin-top: 70px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-90 {
	margin-top: 90px;
}

.mt-100 {
	margin-top: 100px;
}

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-70 {
	margin-bottom: 70px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-90 {
	margin-bottom: 90px;
}

.mb-100 {
	margin-bottom: 100px;
}

.mtb-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.mtb-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.mtb-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.mtb-20 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.mtb-30 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.mtb-40 {
	margin-top: 40px;
	margin-bottom: 40px;
}

.mtb-50 {
	margin-top: 50px;
	margin-bottom: 50px;
}

.mtb-60 {
	margin-top: 60px;
	margin-bottom: 60px;
}

.mtb-70 {
	margin-top: 70px;
	margin-bottom: 70px;
}

.mtb-80 {
	margin-top: 80px;
	margin-bottom: 80px;
}

.mtb-90 {
	margin-top: 90px;
	margin-bottom: 90px;
}

.mtb-100 {
	margin-top: 100px;
	margin-bottom: 100px;
}
/* Translate Center */
.center-x {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.center-y {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
/* Center */
.aligncenter {
	margin: 0 auto;
}
