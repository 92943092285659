//
// Project Color Definitions
//
$white: #ffffff;
$black: #575860;
$blue: #b7d7e5;
$lightblue: #55C2EC;
$grey: #f6f6f6;
$lightgrey: #fbfbfb;
$green: #bfd178;

//
// Background Color
//

.bg-white{
  background-color: $white;
}

.bg-black{
  background-color: $black;
}

.bg-blue{
  background-color: $blue;
}

.bg-lightblue{
  background-color: $lightblue;
}

.bg-grey{
  background-color: $grey;
}

.bg-lightgrey{
  background-color: $lightgrey;
}

.bg-gradient{
  background: #cedf89; /* Old browsers */
  background: -moz-linear-gradient(left, #cedf89 1%, #b7d7e5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #cedf89 1%,#b7d7e5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #cedf89 1%,#b7d7e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cedf89', endColorstr='#b7d7e5',GradientType=1 ); /* IE6-9 */
}

.bg-img-gradient{
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  background-position: 0;
}

.bg-img-gradient-full{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 99;
  background: #cedf89; /* Old browsers */
  background: -moz-linear-gradient(left, #cedf89 1%, #b7d7e5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #cedf89 1%,#b7d7e5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #cedf89 1%,#b7d7e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cedf89', endColorstr='#b7d7e5',GradientType=1 ); /* IE6-9 */
  opacity: 0.9;
}


//
// Text Color
//

.black, .black a, .black p, .black h1, .black h2, .black h3, .black h4, .black h5, .black h6, .black h7, .black h8, .black h9, .black strong{
	color: $black;
}

.blue, .blue a, .blue p, .blue h1, .blue h2, .blue h3, .blue h4, .blue h5, .blue h6, .blue h7, .blue h8, .blue h9, .blue strong{
	color: $blue;
}

.white, .white a, .white p, .white h1, .white h2, .white h3, .white h4, .white h5, .white h6, .white h7, .white h8, .white h9, .white strong, .white li{
	color: $white;
}

.lightblue, .lightblue a, .lightblue p, .lightblue h1, .lightblue h2, .lightblue h3, .lightblue h4, .lightblue h5, .lightblue h6, .lightblue h7, .lightblue h8, .lightblue h9, .lightblue strong{
	color: $lightblue;
}

.grey, .grey a, .grey p, .grey h1, .grey h2, .grey h3, .grey h4, .grey h5, .grey h6, .grey h7, .grey h8, .grey h9, .grey strong{
	color: $grey;
}
