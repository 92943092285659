.carouselA .owl-item, .carouselE .owl-item, .carouselF .owl-item {
  padding: 40px 0;
}

.carouselA .center, .carouselE .center, .carouselF .center {
  padding: 0;
  z-index: 99;
  -webkit-box-shadow: 0 0 50px -30px rgba(82,82,82,1);
  -moz-box-shadow: 0 0 50px -30px rgba(82,82,82,1);
  box-shadow: 0 0 50px -30px rgba(82,82,82,1);
}

.owl-stage-outer{
  padding: 40px 0;
}

.carousel-content {
  background: $white;
  height: 520px;
  border-radius: 15px;
  overflow: hidden;
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin: 0 20px;
}

.carousel-content img {
  width: 100%;
  position: relative;
  float: left;
}

.center .carousel-content {
  background: $white;
  height: 600px;
  opacity: 1;
  margin: 0;
}

.carousel-content .button {
  display: none;
  margin-top: 30px;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.center .carousel-content .button {
  display: block;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.owl-stage-outer{
  height: 680px;
}

.carousel-fix {
  min-height: 700px;
  max-width: 1280px;
}
/* Feel free to change duration  */
.animated {
  -webkit-animation-duration: 1000 ms;
  animation-duration: 1000 ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* .owl-animated-out - only for current item */
/* This is very important class. Use z-index if you want move Out item above In item */
.owl-animated-out {
  z-index: 1;
}
/* .owl-animated-in - only for upcoming item
/* This is very important class. Use z-index if you want move In item above Out item */
.owl-animated-in {
  z-index: 0;
}
/* .fadeOut is style taken from Animation.css and this is how it looks in owl.carousel.css:  */
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/* owl Carousel NAV */

.carousel-fix .owl-dots{
  background: rgba(0,0,0,0);
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}


.carousel-fix .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.carousel-fix .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.carousel-fix .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.carousel-fix .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.carousel-fix .owl-dots .owl-dot.active span,
.carousel-fix .owl-dots .owl-dot:hover span {
  background: #4DC7A0;
}

.carousel-fix .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.carousel-fix .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.carousel-fix .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.carousel-fix .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.carousel-fix .owl-dots .owl-dot.active span,
.carousel-fix .owl-dots .owl-dot:hover span {
  background: $blue !important;
  cursor: pointer;
}

.carousel-fix .owl-dots.disabled,
.carousel-fix .owl-nav.disabled {
  display: none;
}

.carousel-fix .owl-dot,
.carousel-fix .owl-nav .owl-next,
.carousel-fix .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.carousel-fix .owl-nav button.owl-next,
.carousel-fix .owl-nav button.owl-prev,
.carousel-fix button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}


.carouselA .owl-nav, .carouselE .owl-nav, .carouselF .owl-nav{
  width: 100%;
  height: 100%;
  position: static;
  top: 0;
  left: 0;
  margin: 0px;
  z-index: 99;
}

.carouselA .owl-nav, .carouselE .owl-nav, .carouselF .owl-nav {
  display: none;
}

.carouselA .owl-carousel:hover .owl-nav, .carouselE .owl-carousel:hover .owl-nav, .carouselF .owl-carousel:hover .owl-nav{
  display: block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.carouselA .owl-prev, .carouselE .owl-prev, .carouselF .owl-prev{
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -15px;
  background-color: $white !important;
  width: 60px;
  height: 60px;
  border-radius: 30px !important;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(79,79,79,0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(79,79,79,0.15);
  box-shadow: 0px 0px 15px 0px rgba(79,79,79,0.15);
  opacity: 0.3;
}

.carouselA .owl-prev span, .carouselE .owl-prev span, .carouselF .owl-prev span{
  font-size: 35px;
  font-family: $baseFont;
  color: $black;
}

.carouselA .owl-prev:hover, .carouselE .owl-prev:hover, .carouselF .owl-prev:hover{
  opacity: 0.7;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}


.carouselA .owl-next, .carouselE .owl-next, .carouselF .owl-next{
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -15px;
  background-color: $white !important;
  width: 60px;
  height: 60px;
  border-radius: 30px !important;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(79,79,79,0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(79,79,79,0.15);
  box-shadow: 0px 0px 15px 0px rgba(79,79,79,0.15);
  opacity: 0.3;
}

.carouselA .owl-next span, .carouselE .owl-next span, .carouselF .owl-next span{
  font-size: 35px;
  font-family: $baseFont;
  color: $black;
}

.carouselA .owl-next:hover, .carouselE .owl-next:hover, .carouselF .owl-next:hover{
  opacity: 0.7;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.carouselA .center h3:hover, .carouselE.center h3:hover, .carouselF .center h3:hover{
  text-decoration: none !important;
}

.owl-carousel .center .cfx a::before{
  content: "+";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $green;
  z-index: 222;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  line-height: 2.4rem;
  font-size: 20px;
  animation: none;
}

.owl-carousel .center .cfx a::before{
  transition: all 600ms ease 0s;
  animation: pulse2 1s infinite;
}

.owl-carousel .center .cfx{
  object-fit: cover;
  overflow: hidden;
}

.owl-carousel .center .cfx img{
-webkit-transition: all 0.8s;
-moz-transition: all 0.8s;
-ms-transition: all 0.8s;
transition: all 0.8s;
-moz-transform: scale(1.0);
-webkit-transform: scale(1.0);
transform: scale(1.0);
}

.owl-carousel .center .cfx:hover img{
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.05) !important;
  -webkit-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}

.page-id-1108 .owl-dots{
  display: none !important;
}
