.ot-tabs .st-tabs ul{
	margin: 0px;
	padding-bottom: 20px;
}

.ot-tabs .st-tabs li a{
	padding: 10px 30px;
	-webkit-transition: none;
	transition: none;
}

.ot-tabs .st-tabs ul li{
	min-width: 0px;
}


.ot-tabs .st-tabs ul li:first-child{
	margin-right: 20px;
}

.ot-tabs .st-tabs ul{
	margin: 0 auto;
	padding-bottom: 40px;
}

#jam-ota-info_80032978_1{
	margin: 0 auto;
}

.ot-vib{
	max-width: 650px;
	padding-top: 40px;
	padding-bottom: 40px;
	margin: 0 auto;
	float: none;
}
