/* Responsive */
@media all and (min-width:$size-XXL2) {}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
