//
// Header
//

.logo{
  width: 180px;
  padding-top: 16px;
  padding-bottom: 5px;
}

header p{
  font-size: 12px;
}

.nav ul{
  margin: 0px;
  position: relative;
  float: right;
}

.nav li{
  position: relative;
  float: left;
  list-style: none;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}



.nav li::before{
  background-color: $green;
  width: 100%;
  height: 5px;
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.nav li:hover::before{
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.nav li a{
  font-family: $baseFont;
  font-weight: 400;
  color: $black;
  font-size: 13px;
  padding: 40px 11px;
  margin: 0px;
}

.navfix li a{
  padding: 30px 11px;
}


.current_page_item a{
  font-weight: 600 !important;
}



.sub-menu{
  position: absolute !important;
  z-index: 99;
  top: 100%;
  width: 300px;
  padding: 0px !important;
  display: none;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 50px -30px rgba(82,82,82,1);
  -moz-box-shadow: 0 0 50px -30px rgba(82,82,82,1);
  box-shadow: 0 0 50px -30px rgba(82,82,82,1);
  left: -30px;
}

.sub-menu li {
  width: 100%;
  position: relative;
  float: left;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transform: none;
  transform: none;
  box-shadow: none;
  -webkit-transition-property: none;
  transition-property: none;
  -webkit-transition-duration: none;
  transition-duration: none;
}

.sub-menu li::before{
  height: 0px;
  background-color: rgba(0,0,0,0);
  -webkit-transform: none;
  transform: none;
  -webkit-transform-origin: none;
  transform-origin: none;
  -webkit-transition-property: none;
  transition-property: none;
  -webkit-transition-duration: none;
  transition-duration: none;
  -webkit-transition-timing-function: none;
  transition-timing-function: none;
}

.sub-menu:hover::before{
  -webkit-transform: none;
  transform: none;
}

.sub-menu li:first-child{
  padding: 0px;
}

.sub-menu li:last-child{
  padding: 0px;
}


.sub-menu li a{
  position: relative;
  width: 100%;
  padding-left: 0px;
  margin: 0px;
  padding: 20px 25px;
  display: block;
  font-weight: 300;
  -webkit-transition: all 0.1s ease !important;
  -moz-transition: all 0.1s ease !important;
  -ms-transition: all 0.1s ease !important;
  transition: all 0.1s ease !important;
}

.sub-menu li:nth-child(2n){
  background-color: rgba(246, 246, 246, 0.9);
}

.sub-menu li:hover{
  background-color: $green;
}

.sub-menu li:hover .sub-menu li{
  background-color: rgba(255, 255, 255, 0.9);
}

.sub-menu li:hover .sub-menu li:nth-child(2n){
  background-color: rgba(246, 246, 246, 0.9);
}

.sub-menu li:hover .sub-menu li:hover{
  background-color: $green;
}

.sub-menu li:hover a{
  color: $white;
}

.sub-menu li:hover .sub-menu li a{
  color: $black;
}

.sub-menu li:hover .sub-menu li:hover a{
  color: $white;
}

.sub-menu .sub-menu{
  left: 100%;
  top: 0;
  display: none !important;
}

.sub-menu li:hover .sub-menu{
  display: block !important;
}


.menu-item-has-children:hover .sub-menu{
  display: block;
}

.current_page_item .sub-menu a{
  font-weight: 400 !important;
}

.menu-item-has-children::after{
  color: $black;
  position: absolute;
  right: 2px;
  top: 42%;
  content: "▾";
  font-size: 12px;
}

.navfix .menu-item-has-children::after {
  top: 40%;
}

.sub-menu .menu-item-has-children::after{
  color: $black;
  position: absolute;
  right: 15px;
  top: 37%;
  content: "▸";
  font-size: 14px;
}

.sub-menu .menu-item-has-children:hover::after{
  color: $white;
}


//
// Header Fix
//

header{
  position: fixed !important;
  z-index: 999;
}

.navfix{
  position: fixed;
  z-index: 999;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-box-shadow: 0px 0px 19px -8px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 19px -8px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 19px -8px rgba(0,0,0,0.4);

}

.navfix .nf-none{
  display: none;
}

.navfix .ptb-20{
  padding-top: 10px;
  padding-bottom: 10px;
}

.navfix .logo{
  padding-top: 13px;
  max-width: 150px;
}

.navfix .sub-menu{
  opacity: 1;
}

.navfix .sub-menu li:nth-child(2n){
  background-color: rgba(246, 246, 246, 1);
}

.navfix .sub-menu li:hover .sub-menu li{
  background-color: rgba(255, 255, 255, 1);
}

.navfix .sub-menu li:hover .sub-menu li:nth-child(2n){
  background-color: rgba(246, 246, 246, 1);
}

.navfix .sub-menu li {
  width: 100%;
  position: relative;
  float: left;
  padding: 0px;
  background-color: rgba(255, 255, 255, 1);
}

.navfix .sub-menu li:hover{
  background-color: $green;
}

.navfix .sub-menu li:hover .sub-menu li:hover{
  background-color: $green;
}

.social-header span{
  font-size: 15px;
  padding: 0 7px;
  color: $black;
  cursor: pointer;
  -webkit-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -ms-transition: all 0.4s ease; transition: all 0.4s ease;
}

.social-header span:hover{
  color: $green;
}

.social-header a, .social-header p{
  position: relative;
  float: left;
}

.social-header-content{
  position: relative;
  float: left;
}

.mtel a, .mtel p, .mtel span{
  font-size: 12px;
  color: $black;
  font-family: $baseFont;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: 0;
  float: left;
}

.mtel{
  padding-left: 10px;
}

.mtel:first-child{
  padding-left: 0px;
}

.mtel span{
  padding-right: 5px;
}

/* Burgermenu */

		.burgermenu{
			position: relative;
			float: right;
			z-index: 999;
			display: none;
		}


		#nav-icon {
			width: 22px;
			height: 18px;
			position: relative;
			float: left;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .5s ease-in-out;
			-moz-transition: .5s ease-in-out;
			-o-transition: .5s ease-in-out;
			transition: .5s ease-in-out;
			cursor: pointer;
			z-index: 9001;
		}

		#nav-icon span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: $black;
			border-radius: 0px;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
		}


		#nav-icon span:nth-child(1) {
			top: 0px;
		}

		#nav-icon span:nth-child(2),
		#nav-icon span:nth-child(3) {
			top: 6px;
		}

		#nav-icon span:nth-child(4) {
			top: 12px;
		}

		#nav-icon.open span:nth-child(1) {
			top: 18px;
			width: 0%;
			left: 50%;
		}

		#nav-icon.open span:nth-child(2) {
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		#nav-icon.open span:nth-child(3) {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}

		#nav-icon.open span:nth-child(4) {
			top: 18px;
			width: 0%;
			left: 50%;
		}

    .res-nav{
      display: none;
    }

    .res-nav p{
      float: left;
      margin: 0px;
      line-height: 1rem;
      font-size: 12px;
      text-transform: uppercase;
      padding-right: 8px;
    }

    .mm-navbar{
      display: none !important;
    }

    .mm-panel_has-navbar{
      padding: 0px !important;
    }

    .mm-listview{
      position: relative !important;
      float: left !important;
      width: 100% !important;
      margin: -20px 0 0 0 !important;
      padding: 0px !important;
    }

    .mm-listview a{
      padding: 15px 15px 15px 25px;
    }

    .mm-listitem::after{
      left: 0px;
    }

    .mm-panel{
      background: $white;
    }

    .mm-listitem .mm-btn_next::before{
      display: none;
    }

    .mm-btn_next{
      border: 1px $green solid !important;
      border-radius: 25px !important;
    }

    .mm-listitem .mm-btn_next:not(.mm-btn_fullwidth){
      width: 30px;
      height: 30px;
      right: 10px;
      top: 8px;
    }

    .mm-btn_next::after{
      right: 13px !important;
    }

    .mm-btn_next::after, .mm-btn_prev::before{
      width: 5px !important;
      height: 5px !important;
    }

    .mm-menu .mm-listview .mm-btn_next::after{
      border-color: $green !important;
    }

    .mm-listview .menu-item-has-children::after{
      content: '';
    }

    .mm-listview ul, .mm-listview .sub-menu .sub-menu{
      display: block !important;
      position: relative !important;
      float: left !important;
      margin: 0px !important;
      padding: 0px !important;
      left: 0px !important;
      top: 0px !important
    }

    .mm-listitem_opened{
      background: $green !important;
      color: $white !important;
    }

    .mm-listitem_opened .mm-btn_next{
      border: 1px $white solid !important;
      background: rgba(0,0,0,0) !important;
    }

    .mm-menu .mm-listview .mm-listitem_opened .mm-btn_next::after{
      border-color: $white !important;
      right: 11px !important;
    }

    .mm-listitem_vertical > .mm-panel{
      padding: 0px !important;
    }

    .mm-listitem_opened .sub-menu{
      background-color: $white !important;
    }

    .mm-listitem_opened .mm-listview a{
      color: $black !important;
    }

    .mm-listitem_opened .mm-listview a:hover{
      background-color: $white;
    }

    .mm-listitem_opened li:hover{
      background-color: $white;
    }

    #mm-1 li{
      background-color: $white !important;
    }

    #mm-1 .mm-btn_next{
      border: 1px $green solid !important;
    }

    #mm-1 .mm-btn_next::after{
      border-color: $green !important;
    }

    #mm-1 .sub-menu li a, #mm-3 .sub-menu li a, #mm-4 .sub-menu li a, #mm-5 .sub-menu li a{
      padding: 20px 25px 20px 40px;
    }

    #mm-1 .mm-btn_next{
      padding: 0px !important;
    }

    #mm-2 .sub-menu li,   #mm-2 .sub-menu li:hover,   #mm-2 .sub-menu li a:hover{
      background-color: #f6f6f6 !important;
    }

    #mm-2 .sub-menu li a{
      padding: 20px 25px 20px 60px;
    }

    /*---Hide reCaptcha v3 visible seal---*/
  .grecaptcha-badge {
  opacity:0 !important;
  visibility: collapse !important;
  display: none !important;
  }


@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
