.accordion-1 h3 {
  text-transform: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: solid;
  border-width: 1px;
  border-color: #797a84;
}

h3.ui-state-active{
  color: $green;
  font-weight: 600;
}

.ui-accordion-content p{
  padding: 10px 10px 20px 10px;
}


.accordion-1 h3::before{
    font-family: $baseFont;
    font-weight: 400;
    color: $black;
    content: "›";
    font-size: 1.6rem;
    height: 30px;
    left: -40px;
    position: absolute;
    width: 30px;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: 1px solid;
    border-radius: 15px;
    line-height: 1.65rem;
    text-align: center;
  }

h3.ui-accordion-header-active::before {
    line-height: 1.5rem;
    color: $green;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);

}
