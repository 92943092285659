.tr-img img{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;
  opacity: 1;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.0);
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}

.tr-img{
  overflow: hidden;
  border-radius: 100px;
  width: 180px;
  height: 180px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

.tr-content h3{
  text-transform: none;
  font-size: 14px;
  font-weight: 300;
}

.tr-content{
  padding-bottom: 40px;
}

.tr-content:first-child{
  margin-left: 0px;
}


.bh-img img{
  -moz-transform: scale(1.00);
  -webkit-transform: scale(1.00);
  transform: scale(1.00);
}

.bh-img:hover img{
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.05) !important;
  -webkit-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}

.bh-img:hover::after{
  content: '';
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  // background: #fff;
  opacity: 0.9;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.treatments .tr-img:hover{
  // border: 3px solid;
  // border-color: $green;
  opacity: 1;
}

.treatments .tr-img:hover img{
  opacity: 1;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.treatments .tr-img:hover .tr-icon{
  // display: block;
  // -webkit-animation-name: bounceInUp;
  // animation-name: bounceInUp;
}

.tr-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  z-index: 50;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: none;
}

.tr-icon img{
  width: 100%;
  height: 100%;
}
